import React, { FormEvent, useEffect, useState } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, Image, Form, Button, Spinner } from "react-bootstrap"
import MetaMaskOnboarding from '@metamask/onboarding'
import Web3 from 'web3'
import { Contract } from "web3-eth-contract"
import { isMobile } from "../../../../utils/device"
import MetaMaskConnectButton from "../../../buttons/MetaMaskConnectButton";
import LCCCLogo from "../../../../images/LCCCLogo.png"
import WelcomeHeader from "../../../../images/LCCCHeader.png"
import LCCCWelcome from "../../../../images/WelcomeLCCC.png"
import LCCCIcon from "../../../../images/LCCCIcon.png"
import LightBulb from "../../../../images/LightBulb.png"
import LCCCMint from "../../../../images/LCCCMint.png"
import OpenseaLogo from "../../../../images/OpenseaLogo.png"
import looksRareLogo from "../../../../images/looksRareLogo.png"
import r1 from "../../../../images/r1.png"
import r2 from "../../../../images/r2.png"
import r3 from "../../../../images/r3.png"
import r4 from "../../../../images/r4.png"
import r5 from "../../../../images/r5.png"
import r6 from "../../../../images/r6.png"
import r7 from "../../../../images/r7.png"
import r8 from "../../../../images/r8.png"
import r9 from "../../../../images/r9.png"
import r10 from "../../../../images/r10.png"
import REAbout from "../../../../images/REAbout.png"
import reSelection from "../../../../images/reSelection.png"
import re1 from "../../../../images/re1.png"
import re2 from "../../../../images/re2.png"
import re3 from "../../../../images/re3.png"
import re4 from "../../../../images/re4.png"
import re5 from "../../../../images/re5.png"
import re6 from "../../../../images/re6.png"
import re7 from "../../../../images/re7.png"
import re8 from "../../../../images/re8.png"
import heritageAuctions from "../../../../images/heritageAuctions.png"
import artsy from "../../../../images/artsy.png"
import phillips from "../../../../images/phillips.png"
import popaganda from "../../../../images/popaganda.png"
import f1 from "../../../../images/f1.png"
import f2 from "../../../../images/f2.png"
import f3 from "../../../../images/f3.jpg"
import f4 from "../../../../images/f4.jpg"
import BulbPrint from "../../../../images/bulbPrint.jpeg"
import PlaceholderBulb from "../../../../images/placeholderBulb.jpeg"

const styles = require("./landing-page.module.scss")

const BURN_WALLET = "0x344d26c62264957FFb42Ec9AbA12E1b342E9e8DA"

const SALE_STATE = Object.freeze(
  {
    CLOSED: 0,
    PRESALE_TIER_ONE: 1,
    PRESALE_TIER_TWO: 2,
    SALE: 3,
  }
);

type HomeProps = {
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

/**
 * Home page for LCCC. Most of the site will be on this page.
 */
const Home: React.FC<HomeProps> = ({ accountAddress, contract, getAndSetAccount }) => {
  // ---- Burn Logic ----
  // General state
  const [isProcessingBurn, setIsProcessingBurn] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [burnTransactionUrl, setBurnTransactionUrl] = useState<string>("");
  const [haveTokenIdsBeenRead, setHaveTokenIdsBeenRead] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isBurnActive, setIsBurnActive] = useState<boolean>(false);

  // Burn form state
  const [tokenIds, setTokenIds] = useState<Array<number>>([]);
  const [selectedToken, setSelectedToken] = useState<number>(-1);
  const [tokenIdsToCardImageUrls, setTokenIdsToCardImageUrls] = useState<Map<number, string>>(new Map());

  // Info form state
  const [nonce, setNonce] = useState<string>("");
  const [signature, setSignature] = useState<string>("");
  const [isProcessingRequest, setIsProcessingRequest] = useState<boolean>(false);
  const [updatedEmail, setUpdatedEmail] = useState<string>("");
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);

  // Wallet address retrieval form state
  const [tokenIdToRetrieve, setTokenIdToRetrieve] = useState<number | "">("");

  // Email retrieval form state
  const [isRetrievingEmail, setIsRetrievingEmail] = useState<boolean>(false);
  const [getEmailNonce, setGetEmailNonce] = useState<string>("");
  const [getEmailSignature, setGetEmailSignature] = useState<string>("")

  // For writing an email address
  useEffect(() => {
    if (!!accountAddress && !!nonce) {
      if (signature === "") {
        signAgreement(accountAddress, updatedEmail, nonce, setSignature);
      } else {
        recordEmail(
          accountAddress,
          updatedEmail,
          signature,
          setModalTitle,
          setModalMessage,
          setEmail,
          setNonce,
          setSignature,
          setIsEditingEmail,
          setIsProcessingRequest);
      }
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddress, nonce, signature]);

  // For retrieving an email address
  useEffect(() => {
    if (!!accountAddress && !!getEmailNonce) {
      if (getEmailSignature === "") {
        signAgreement(accountAddress, undefined, getEmailNonce, setGetEmailSignature);
      } else {
        retrieveEmail(
          accountAddress,
          getEmailSignature,
          setModalTitle,
          setModalMessage,
          setEmail,
          setUpdatedEmail,
          setIsRetrievingEmail);
      }
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddress, getEmailNonce, getEmailSignature]);


  // Function to actually do the minting
  const burnBulb = () => {
    const data = {
      from: accountAddress,
      value: 0,
    }

    return contract.methods.burn(selectedToken).send(data)
      .on('transactionHash', (hash: string) => {
        setBurnTransactionUrl("https://etherscan.io/tx/" + hash);
      });
  }

  useEffect(() => {
    // Buy Bulb
    if (isProcessingBurn) {
      if (selectedToken === -1) {
        setIsProcessingBurn(false);
        setModalTitle("Unable to Burn");
        setModalMessage("You must select a Bulb to burn.");
      } else {
        burnBulb().then(() => {
          contract.methods.listTokensForOwner(accountAddress).call().then((tokenIds: Array<number>) => {
            setTokenIds(tokenIds);
            setSelectedToken(-1);
            setIsProcessingBurn(false);
          });
        }).catch(() => {
          setIsProcessingBurn(false);
        });
      }
    }
  }, [isProcessingBurn]);

  useEffect(() => {
    if (accountAddress && contract) {
      contract.methods.listTokensForOwner(accountAddress).call().then((tokenIds: Array<number>) => {
        setTokenIds(tokenIds);
        setHaveTokenIdsBeenRead(true);
      });
    }
  }, [contract, accountAddress]);

  const setCardImageUrls = (async () => {
    const updatedTokenIdsToCardImageUrls = new Map();
    for (const tokenId of tokenIds) {
      const response = await axios.get("https://ipfs.io/ipfs/QmXpXg2KDaqtFwFutCx11Lib4BJ86RPkUpkxCJJCDSc5SL/" + tokenId);
      const prefix = "ipfs://";
      const ipfsHash = response.data.image.slice(prefix.length);
      const imageUrl = "https://ipfs.io/ipfs/" + ipfsHash;
      updatedTokenIdsToCardImageUrls.set(tokenId, imageUrl);
    }

    setTokenIdsToCardImageUrls(updatedTokenIdsToCardImageUrls);
  });

  useEffect(() => {
    setCardImageUrls();
  }, [tokenIds]);

  const renderedBulbs = tokenIds.map((tokenId) => {
    const imageUrl = tokenIdsToCardImageUrls.get(tokenId);
    const cardContent =
      imageUrl
        ? <Image className={styles.bulbImageBurn} src={imageUrl} alt={PlaceholderBulb} />
        : <Image className={styles.bulbImageBurn} src={PlaceholderBulb} />
    return (
      <Col className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3" key={`bulb-${tokenId}`}>
        <div
          className={
            selectedToken === tokenId
              ? styles.bulbSelectedCard
              : styles.bulbCard}
          onClick={() => {
            if (selectedToken === tokenId) {
              setSelectedToken(-1);
            } else {
              setSelectedToken(tokenId);
            }
          }}
        >
          <div className={styles.selectedCard}>SELECTED</div>
          {cardContent}
          <div className={styles.tokenId}>{tokenId}</div>
        </div>
      </Col>
    );
  });

  const onBurn = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsProcessingBurn(true);
  }

  const onEmailSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!updatedEmail.includes("@")) {
      setModalTitle("Invalid Email Address");
      setModalMessage("Please input a valid email address.");
      return;
    }

    if (accountAddress) {
      setIsProcessingRequest(true);
      setNonceForAddress(accountAddress, setModalTitle, setModalMessage, setNonce, setIsProcessingRequest);
    }
  }

  const onVerify = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (accountAddress) {
      setIsRetrievingEmail(true);
      setNonceForAddress(accountAddress, setModalTitle, setModalMessage, setGetEmailNonce, setIsRetrievingEmail);
    }
  }

  const onRetrieveBurner = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    contract.methods.ownerOf(tokenIdToRetrieve).call()
      .then((ownerAddress: string) => {
        if (ownerAddress === BURN_WALLET) {
          setModalTitle("Burn Status");
          setModalMessage(`Token #${tokenIdToRetrieve} has been burned`);
          return;
        }

        contract.methods.burnerOf(tokenIdToRetrieve).call()
          .then((burnerAddress: string) => {
            if (burnerAddress === "0x0000000000000000000000000000000000000000") {
              setModalTitle("Token not burned");
              setModalMessage(`Token #${tokenIdToRetrieve} has not been burned.`);
              return;
            }
    
            setModalTitle("Burn Status");
            setModalMessage(`Token #${tokenIdToRetrieve} has been burned`);
          });
      }).catch((err) => {
        setModalTitle("Token not burned");
        setModalMessage(`Token #${tokenIdToRetrieve} has not been burned.`);
      })
  }

  const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
  const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;

  const buttonText = isMetaMaskInstalled ? "Connect to MetaMask" : "Install MetaMask";

  const onClickConnect = async () => {
    try {
      // This will open the MetaMask UI
      await ethereum.request({ method: 'eth_requestAccounts' });
      getAndSetAccount();
    } catch (error) {
      console.error(error);
    }
  };

  const onClickInstall = () => {
    const forwarderOrigin = 'http://localhost:8000';
    const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
    onboarding.startOnboarding();
  };

  // ---- Mint Logic ----

  // General state
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [transactionUrl, setTransactionUrl] = useState("");

  // Contract state
  const [saleState, setSaleState] = useState<number>(0); // 0 = closed, 1 = presale tier 1, 2 = presale tier 2, 3 = sale
  const [currentSupply, setCurrentSupply] = useState<number>(0);
  const [supplyLimit, setSupplyLimit] = useState<number>(0);
  const [isTierOneEligible, setIsTierOneEligible] = useState(false);
  const [isTierTwoEligible, setIsTierTwoEligible] = useState(false);
  const [presaleAllowance, setPresaleAllowance] = useState<number>(0);
  const [mintPrice, setMintPrice] = useState<number>(0.03);

  // Form state
  const [numBulbsToBuy, setNumBulbsToBuy] = useState<string>("1");

  // Function to do the minting
  const mintBulbs = () => {
    return contract.methods.mintPrice().call().then((currentPrice: number) => {
      const numBulbsBuying: number = parseInt(numBulbsToBuy);
      const gasLimit = 210000 * numBulbsBuying - (((210000 * numBulbsBuying) / 100) * (numBulbsBuying - numBulbsBuying * 0.2));

      return contract.methods.mintTokens(numBulbsBuying).send(
        {
          from: accountAddress,
          value: currentPrice * numBulbsBuying,
          gas: gasLimit
        })
        .on('transactionHash', (hash) => {
          setTransactionUrl("https://etherscan.io/tx/" + hash);
      })
    })
  }

  // Sets initial price
  useEffect(() => {
    // Buy Bulbs
    if (isLoading) {
      mintBulbs().then(() => {
        setIsLoading(false);
      }).catch(e => {
        setIsLoading(false);
        setShouldShowError(true);
      });
    }
    if (contract && accountAddress) {
      contract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply);
      });

      contract.methods.mintPrice().call().then((price: number) => {
        setMintPrice(price / 1000000000000000000);
      });

      contract.methods.TOKEN_LIMIT().call().then((bulbLimit: number) => {
        setSupplyLimit(bulbLimit);
      });

      contract.methods.saleState().call().then((saleState: string) => {
        setSaleState(parseInt(saleState));
      });

      contract.methods.burnActive().call().then((isActive: boolean) => {
        setIsBurnActive(isActive);
      });

      if (accountAddress) {
        contract.methods.isTierOnePresaleEligible(accountAddress).call().then((isTierOnePresaleEligible: boolean) => {
          setIsTierOneEligible(isTierOnePresaleEligible);
        });

        contract.methods.isTierTwoPresaleEligible(accountAddress).call().then((isTierTwoPresaleEligible: boolean) => {
          setIsTierTwoEligible(isTierTwoPresaleEligible);
        });

        contract.methods.presaleAllowanceForAddress(accountAddress).call().then((presaleNumber: string) => {
          if (saleState === SALE_STATE.PRESALE_TIER_ONE) {
            setPresaleAllowance(parseInt(presaleNumber) - 1);
          } else {
            setPresaleAllowance(parseInt(presaleNumber));
          }
        });
      }
    }
  }, [contract, isLoading, currentSupply, accountAddress, saleState]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
  }

  const hasSaleStarted = saleState === SALE_STATE.SALE;
  const hasPresaleStarted = saleState > SALE_STATE.CLOSED;
  const remainingBulbs = saleState > SALE_STATE.CLOSED ? String(supplyLimit - currentSupply) : "8859";
  const maxMintNumber = hasSaleStarted || (saleState === SALE_STATE.PRESALE_TIER_TWO && isTierTwoEligible) ? 2 : Math.min(presaleAllowance, 2);
  const isSoldOut = ((supplyLimit === currentSupply) && hasSaleStarted);
  const isMobileAndMetamaskNotInstalled = isMobile() && !isMetaMaskInstalled
  const isNotMobileAndMetamaskNotInstalled = !isMobile() && !isMetaMaskInstalled
  const isSaleActive = !isSoldOut && hasPresaleStarted;
  const isEligibleToMint =
    hasSaleStarted
    || (isTierOneEligible && saleState === SALE_STATE.PRESALE_TIER_ONE && presaleAllowance > 0)
    || (isTierTwoEligible && saleState === SALE_STATE.PRESALE_TIER_TWO && presaleAllowance > 0);
    const shouldShowMintButtonRow = (!isSoldOut && hasSaleStarted) || isEligibleToMint;

  return (
    <>
    <Container id="welcome" className={`${styles.section} ${styles.welcomeHeader}`} style={{marginTop: "5rem"}}>
        <div className={styles.bulbVideoContainer}>
          <iframe
            className={styles.bulbVideo}
            src="https://www.youtube.com/embed/DpH2YnlFfo4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
        <Row>
          <Col className={`d-flex flex-column align-items-start justify-content-center ${styles.welcomeSectionContent}`}>
            <Row className="d-flex align-items-center justify-content-start">
              <Col className="col-12 col-sm-12 col-lg-8">
                <h1 className={styles.welcomeTextHeader} style={{marginBottom: "0px"}}>LIGHT CULT CRYPTO CLUB</h1>
                <h1 className={styles.welcomeTextHeader}>NFT COLLECTION</h1>
                <h1 className={styles.alternateWelcomeTextHeader}>LIGHT CULT CRYPTO CLUB NFT COLLECTION</h1>
                <Row className="d-flex align-items-center justify-content-start">
                  <Col className="col-12 col-sm-12 col-lg-10">
                    <p className={styles.textContentRoboto16}>
                      Ron English has co-opted the metaverse. The New York contemporary artist and prolific culture-jammer has designed a collection of
                      NFT’s that distill some of his greatest artistic ideas of the last 40 years of his career into the universal symbol for the
                      idea - the light bulb. Welcome to the light-cult, 10,008 ideas from the brain of Ron himself.
                    </p>
                  </Col>
                </Row>
                <Row className="d-flex flex-column">
                  <Col className={styles.lightBulbBulletPoint}>
                    <Image className={styles.lightBulbImage} src={LightBulb} />
                    <p className={styles.lightBulbBulletText}>8,792 unique NFTs</p>
                  </Col>
                  <Col className={styles.lightBulbBulletPoint}>
                    <Image className={styles.lightBulbImage} src={LightBulb} />
                    <p className={styles.lightBulbBulletText}>146 unique attributes</p>
                  </Col>
                  <Col className={styles.lightBulbBulletPoint}>
                    <Image className={styles.lightBulbImage} src={LightBulb} />
                    <p className={styles.lightBulbBulletText}>1008 hand-drawn 1/1 rare artworks</p>
                  </Col>
                  <Col className={styles.lightBulbBulletPoint}>
                    <Image className={styles.lightBulbImage} src={LightBulb} />
                    <p className={styles.lightBulbBulletText}>Deflationary supply as NFTs can be burned</p>
                  </Col>
                  <Col className={styles.lightBulbBulletPoint}>
                    <Image className={styles.lightBulbImage} src={LightBulb} />
                    <p className={styles.lightBulbBulletText}>Monthly burn for Physical Goods, Future Drop, & Exclusive Access</p>
                  </Col>
                  <Col>
                    <h1 className={styles.soldOutText}>The Collection is SOLD OUT!</h1>
                  </Col>
                  <Row className={styles.collectionLinksContainer}>
                    <Col className={styles.externalLinkButtonContainer}>
                      <a
                        className={styles.externalLinkButton}
                        href="https://opensea.io/collection/lightcultcryptoclub"
                        target="_blank"
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <Image
                            src={OpenseaLogo}
                            className={styles.externalLinkLogo}
                          />

                          <span>VIEW ON OPENSEA</span>
                        </div>
                      </a>
                    </Col>
                    <Col className={styles.externalLinkButtonContainer}>
                      <a
                        className={styles.externalLinkButton}
                        href="https://looksrare.org/collections/0xbE85fBd182af91290be7293438AE67549638189f"
                        target="_blank"
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <Image
                            src={looksRareLogo}
                            className={styles.externalLinkLogo}
                          />

                          <span>LOOKSRARE</span>
                        </div>
                      </a>
                    </Col>
                  </Row>
                  <Col className={styles.smartContractAddressContainer}>
                    <p className={styles.textContent12}><span className={styles.textContentBold12}>VERIFIED SMART CONTRACT ADDRESS:</span> <a className={styles.contractLink} href="https://etherscan.io/address/0xbe85fbd182af91290be7293438ae67549638189f" target="_blank">0xbe85fbd182af91290be7293438ae67549638189f</a></p>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-center">
                <Image src={LCCCIcon} className={styles.homeAboutImage} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalMessage != ""}
        onHide={() => {
          setModalTitle("");
          setModalMessage("");
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalTitle("");
              setModalMessage("");
            }}
            className={styles.bulbDismissButton}
          >
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Container id="burn" className={`${styles.section} ${styles.burnContainer}`}>
        <Row className={styles.burnHeaderContainer}>
          <Col>
            <h1 className={styles.textHeader}>BURN A BULB</h1>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-8">
            <p className={styles.textContent16}>
              Each month, for a one week period holders will be able to burn any bulb to receive a physical object or NFT version by Ron English.
              The burn period will open during the last week of every month. This burn period closes when 85 bulbs are burned or at 11:59 PM EST on August 30th. Please visit
              our Discord for more details.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-lg-6">
            <Image src={BulbPrint} />
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-12 col-lg-6">
            <h1 className={styles.burnSubHeader} style={{ textAlign: "center", marginBottom: "0px" }}>BURNED BULB #9</h1>
            <h1 className={styles.burnSubHeader} style={{ textAlign: "center", marginBottom: "1.45rem" }}>GRENADE SCULPTURE</h1>
            <p className={styles.textContentRoboto18}>6 Inches</p>
            <p className={styles.textContentRoboto18} style={{ marginBottom: "0px" }}>3D Printed PLA Filament</p>
            <p className={styles.textContentRoboto18}>Each Piece is Hand-Painted by Ron</p>
            <p className={styles.textContentRoboto18} style={{ marginBottom: "0px" }}>Edition of 85</p>
            <p className={styles.textContentRoboto18}>This burn is first come first served. Limited to 85 pieces.</p>
            <p className={styles.textContentRoboto18}>Certificate of Authenticity</p>
            <p className={styles.textContentRoboto18} style={{ fontWeight: "bold" }}>Signed and Numbered by Ron English</p>

            {(!!accountAddress && haveTokenIdsBeenRead && tokenIds.length === 0 && isBurnActive) && (
              <>
                <Row>
                  <Col>
                    <p className={styles.textContentRoboto18} style={{ fontWeight: "bold" }}>The connected wallet does not contain any Bulbs to burn</p>
                  </Col>
                </Row>
              </>
            )}

            {(isMobileAndMetamaskNotInstalled) && (
              <Row>
                <Col className={styles.externalLinkButtonContainer}>
                  <Button variant="primary" className={styles.soldOutButton}>
                    BURN OPEN
                  </Button>
                </Col>
              </Row>
            )}

            {(!accountAddress && !isMobile() || (isMobile() && isMetaMaskInstalled && !accountAddress)) && (
              <>
                <Row className={styles.burnIntroButtonContainer}>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        if (accountAddress) {
                          setModalTitle("No Bulbs");
                          setModalMessage("The connected wallet does not contain any Bulbs.");
                        } else {
                          if (isMetaMaskInstalled) {
                            onClickConnect();
                          } else {
                            onClickInstall();
                          }
                        }
                      }}
                      className={styles.bulbConnectButton}
                    >
                      {buttonText}
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            {(!!accountAddress && tokenIds.length !== 0 && (!getEmailSignature || isRetrievingEmail) && isBurnActive) && (
              <Form noValidate onSubmit={onVerify}>
                <Row>
                  <Col>
                    <p className={styles.textContent16}>Before you send your Bulb to the great beyond you first need to authenticate your wallet by providing a signature.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button
                      className={styles.bulbButton}
                      type="submit"
                      variant="primary"
                    >
                      {isRetrievingEmail
                        ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        : "AUTHENTICATE"
                      }
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>

        {(!!accountAddress && tokenIds.length !== 0 && !!getEmailSignature && !isRetrievingEmail && !email) && (
          <Form noValidate onSubmit={onEmailSubmit}>
            <Row>
              <Col>
                <p className={styles.textContent16}>
                  In order to coordinate the shipping of your physical print we will be sending confirmation, as well as additional details of your order, to the
                  designated email address that you provide after this month's burn period has closed, which is when 85 bulbs are burned or at 11:59 PM EST on August 30th. Any email provided will be viewable just by the LCCC team and
                  will be used solely as required for the delivery of your physical print.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-center">
                <p className={styles.textContent16} style={{marginBottom: "0px", marginRight: "8px"}}>
                  Email address:
                </p>
                <Form.Control
                  className={styles.burnInput}
                  type="email"
                  placeholder="Enter email"
                  value={updatedEmail}
                  onChange={({ target: { value } }) => {
                    setUpdatedEmail(value);
                  }}
                />
                <Button
                  className={styles.bulbSubmitButton}
                  type="submit"
                  disabled={updatedEmail === ""}
                  variant="primary"
                >
                  {isProcessingRequest
                    ? <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    : "SUBMIT"
                  }
                </Button>
              </Col>
            </Row>
          </Form>
        )}

        {(!!accountAddress && tokenIds.length !== 0 && !!getEmailSignature && !!email) && (
          <Form noValidate onSubmit={onEmailSubmit}>
            <Row>
              {!isEditingEmail && (
                <Col>
                  <p className={styles.textContent16}>
                    In order to coordinate the shipping of your physical print we will be sending confirmation, as well as additional details of your order, to the
                    designated email address that you provide after this month's burn period has closed, which is when 85 bulbs are burned or at 11:59 PM EST on August 30th. Any email provided will be viewable just by the LCCC team and
                    will be used solely as required for the delivery of your physical print.
                  </p>
                  <div className="d-flex align-items-center">
                    <p className={styles.textContent16} style={{marginBottom: "0px"}}>
                      Email address: <span className={styles.bold}>{email}</span>
                    </p>
                    <Button
                      className={styles.bulbSubmitButton}
                      variant="primary"
                      style={{marginLeft: "8px"}}
                      onClick={() => setIsEditingEmail(true)}
                    >
                      EDIT
                    </Button>
                  </div>
                </Col>
              )}
              {!!isEditingEmail && (
                <Col>
                  <p className={styles.textContent16}>
                    In order to coordinate the shipping of your physical print we will be sending confirmation, as well as additional details of your order, to the
                    designated email address that you provide after this months burn period has closed. Any email provided will be viewable just by the LCCC team and
                    will be used solely as required for the delivery of your physical print.
                  </p>
                  <div className="d-flex align-items-center">
                    <p className={styles.textContent16} style={{marginBottom: "0px", marginRight: "8px"}}>
                      Email address:
                    </p>
                    <Form.Control
                      className={styles.burnInput}
                      type="email"
                      placeholder="Enter email"
                      value={updatedEmail}
                      onChange={({ target: { value } }) => {
                        setUpdatedEmail(value);
                      }}
                    />
                    <Button
                      className={styles.bulbSubmitButton}
                      type="submit"
                      disabled={updatedEmail === ""}
                      variant="primary"
                    >
                      {isProcessingRequest
                        ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        : "SUBMIT"
                      }
                    </Button>
                    <Button
                      className={styles.bulbLinkButton}
                      variant="link"
                      onClick={() => setIsEditingEmail(false)}
                    >
                      CANCEL
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        )}

        <Form noValidate onSubmit={onBurn}>
          {(!!accountAddress && tokenIds.length !== 0 && !!email) && (
            <>
              <Row>
                <Col>
                  <p className={styles.textContent16}>
                    Select a Bulb to send to the great beyond - never to return again. <span style={{ fontWeight: "bold" }}>NOTE: There is no going back after burning your Bulb. When it's gone, it's gone.</span>
                  </p>
                  <p className={styles.textContent16} style={{ fontWeight: "bold" }}>WARNING! This is not the burn for rare option.</p>
                  <p className={styles.textContent16} style={{ fontWeight: "bold" }}>WARNING! You are burning for one of the randomized variations of the print above, not a print of the bulb you are burning.</p>
                </Col>
              </Row>

              <Row className={`d-flex align-items-center justify-content-start ${styles.cardsContainer}`}>
                {renderedBulbs}
              </Row>

              <Row className={styles.burnButtonContainer}>
                <Col className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    className={styles.bulbButton}
                    disabled={selectedToken === -1}
                    variant="primary"
                    onClick={() => {
                      setIsProcessingBurn(true);
                    }}
                  >
                    {
                      isProcessingBurn
                        ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        : selectedToken === -1 ? "BURN" : `BURN #${selectedToken}`
                    }
                  </Button>
                  {burnTransactionUrl !== "" && (
                    <a href={burnTransactionUrl} target="_blank" className={styles.transactionUrl}>View transaction</a>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Container> */}

      <Container>
        <Row>
          <Col>
            <div className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="about" className={`${styles.section} ${styles.aboutRonEnglishSection}`}>
        <Row className={styles.sectionHeader}>
          <Col>
            <h1 className={styles.textHeader}>ABOUT RON ENGLISH</h1>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <Image src={REAbout} className={styles.aboutHeaderImage} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textContent18}>
              Ron English is widely considered a seminal figure in the advancement of street art away from traditional wild-style lettering into
              clever statement and masterful trompe l’oeil based art. He has created illegal murals and billboards that blend stunning visuals
              with biting political, consumerist and surrealist statements, hijacking public space worldwide for the sake of art since the 1980s.
            </p>
            <span className={styles.textContentBold18}>
              <a className={styles.socialLink} href="https://twitter.com/ronenglishart" target="_blank">Twitter</a>
              {`  //  `}
              <a className={styles.socialLink} href="https://www.facebook.com/ronenglishpresentspopaganda/" target="_blank">FB</a>
              {`  //  `}
              <a className={styles.socialLink} href="https://www.instagram.com/ronenglish" target="_blank">IG</a>
              {`  //  `}
              <a className={styles.socialLink} href="http://www.popaganda.com/" target="_blank">Website</a>
              {`  //  `}
              <a className={styles.socialLink} href="https://en.wikipedia.org/wiki/Ron_English" target="_blank">Wikipedia</a>
            </span>
          </Col>
        </Row>
        <Row className={styles.showsContainer}>
          <Col className={`${styles.groupShowsContainer} col-12 col-lg-6`}>
            <h4 className={styles.textContentBold18}>Group Shows - 2021</h4>
            <p className={styles.textContent18}>The Last Word, Ethan Cohen Gallery</p>
            <p className={styles.textContent18}>The Locals, 9 Strokes Gallery</p>
            <p className={styles.textContent18}>Summer in Tribeca, Black Wall Street Gallery</p>
            <p className={styles.textContent18}>BEYOND THE STREETS on Paper</p>
            <p className={styles.textContent18}>Scatter Sale, Signari Gallery</p>
            <p className={styles.textContent18}>Echoes, KP Projects</p>
            <p className={styles.textContent18}>Vault Sale, Signari Gallery</p>
            <p className={styles.textContent18}>Spring Show, BPO Premier Test</p>
            <p className={styles.textContent18}>Abracadabra Selection, Dorothy Circus Gallery</p>
            <p className={styles.textContent18}>Project 270, Mana Contemporary</p>
          </Col>
          <Col className="col-12 col-lg-6">
            <h4 className={styles.textContentBold18}>Solo Shows</h4>
            <p className={styles.textContent18}>2021 - Ron English: Brand Royalty, Allouche Gallery</p>
            <p className={styles.textContent18}>2020 - POPaganda on Paper, Pop International Galleries</p>
            <p className={styles.textContent18}>2018 - Ron English: Universal Grin, Galerie Matthew Namour</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className={styles.subHeader}>SELECTED WORKS</h2>
          </Col>
        </Row>
        <Row className={styles.groupedSelectedWorks}>
          <Col>
            <Image src={reSelection} className={styles.selectedWorks} />
          </Col>
        </Row>
        <div className={styles.individualSelectedWorks}>
          <Row className="d-flex align-items-center justify-content-center">
            <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <Image src={re1} className={styles.selectedWorks} />
            </Col>
            <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <Image src={re2} className={styles.selectedWorks} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center">
            <Col className="col-12 col-lg-3 d-flex align-items-center justify-content-center">
              <Image src={re3} className={styles.selectedWorks} />
            </Col>
            <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
              <Image src={re4} className={styles.selectedWorks} />
            </Col>
            <Col className="col-12 col-lg-3 d-flex align-items-center justify-content-center">
              <Image src={re5} className={styles.selectedWorks} />
            </Col>
            <Col className="col-12 col-lg-2 d-flex align-items-center justify-content-center">
              <Image src={re6} className={styles.selectedWorks} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center">
            <Col className="col-12 col-lg-8 d-flex align-items-center justify-content-center">
              <Image src={re7} className={styles.selectedWorks} />
            </Col>
            <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
              <Image src={re8} className={styles.selectedWorks} />
            </Col>
          </Row>
        </div>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="d-flex align-items-center justify-content-between flex-wrap">
            <a href="https://www.ha.com/c/search-results.zx?N=0+793+794+791+792+1893+1577+2088&Ntk=SI_Titles-Desc&Nty=1&Ntt=Ron+English&limitTo=all&ic=homepage-search-A-K-071316" target="_blank">
              <Image src={heritageAuctions} className={styles.selectedWorks} />
            </a>
            <a href="https://www.artsy.net/artist/ron-english" target="_blank">
              <Image src={artsy} className={styles.selectedWorks} />
            </a>
            <a href="https://www.phillips.com/artist/6888/ron-english" target="_blank">
              <Image src={phillips} className={styles.selectedWorks} />
            </a>
            <a href="http://www.popaganda.com/" target="_blank">
              <Image src={popaganda} className={styles.selectedWorks} />
            </a>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="roadmap" className={styles.section}>
        <Row className={styles.sectionHeader}>
          <Col>
            <h1 className={styles.textHeader}>ROADMAP</h1>
          </Col>
        </Row>
        <Row className="d-flex flex-column">
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>We’re building the future, one step at a time.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>Minting starts in November 2021.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>Limited-edition merchandise designed by Ron will be released and available exclusively to Bulbheads.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>250 Bulb holders will be selected at random to receive a physical limited edition art print signed by Ron.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>25 Bulb holders will be chosen to receive a handmade resin sculpture.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>1 Bulb holder will be chosen to receive an original oil painting by Ron English.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>Option to burn an NFT for a physical print or rare NFT. There will be 12 burns in 2022 where you can choose to burn any one of your Bubs for a signed limited-edition print by Ron English.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>Invitation to our exclusive exhibition for Bulb holders in NYC in 2022.</p>
          </Col>
          <Col className={styles.lightBulbBulletPoint}>
            <Image className={styles.lightBulbImage} src={LightBulb} />
            <p className={styles.lightBulbBulletText}>Exclusive airdrops, merch, giveaways, in-person events, and more will be available to Bulbheads on an ongoing basis.</p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="rarity" className={styles.section}>
        <Row>
          <Col className="d-flex flex-column align-items-start justify-content-center">
            <Row className={styles.sectionHeader}>
              <Col>
                <h1 className={styles.textHeader}>RARITY</h1>
              </Col>
            </Row>
            <Row className={styles.rarityImages}>
              <Col className="col-12 col-sm-12 col-lg-12 d-flex align-items-center justify-content-start flex-wrap">
                <Row className="d-flex align-items-center justify-content-start flex-wrap">
                  <Col>
                    <Image src={r1} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r2} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r3} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r4} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r5} className={styles.rarityImage} />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-start flex-wrap">
                  <Col>
                    <Image src={r6} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r7} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r8} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r9} className={styles.rarityImage} />
                  </Col>
                  <Col>
                    <Image src={r10} className={styles.rarityImage} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={styles.textContent16}>
                  Every character, trait, and Idea in the Light Cult metaverse was conceived, hand-drawn, and digitized solely by Ron English.
                  Most Bulb NFTs are Uncommons; 1 in 10 are Rares.
                </p>
                <p className={styles.textContent16}>
                  Uncommons are 100% unique with randomly generated assets. No two are alike, but some traits are rarer than others. There are 2.7
                  million possible combinations of traits but only 9,000 Uncommons exist.
                </p>

                <p className={styles.textContent16}>
                  Rares are handmade masterpieces born from the brain of Ron English. Every Rare is a one-of-a-kind NFT with absurd qualities reflecting
                  those of the Inner Circle. 
                </p>
                <p className={styles.textContent16}>
                  “My best attribute is that I'm an endless source of ideas. What’s the biggest problem with being me, psychologically? I wake up with
                  10,000 new ideas every morning, and there’s absolutely no way the world can absorb 10,000 ideas by Ron English. Until now.” 
                </p>
                <p className={styles.textContentAuthor}>-Ron English</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="team" className={styles.section}>
        <Row className={styles.sectionHeader}>
          <Col>
            <h1 className={styles.textHeader}>THE FOUNDERS</h1>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center flex-wrap">
          <Col className="d-flex flex-column align-items-center justify-content-center col-12 col-md-6">
            <Row className="d-flex align-items-center justify-content-start flex-wrap">
              <Col className={styles.founderImageContainer}>
                <Image src={f1} className={styles.founderImage} />
              </Col>
              <Col className={styles.founderImageContainer}>
                <Image src={f2} className={styles.founderImage} />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-start flex-wrap">
              <Col className={styles.founderImageContainer}>
                <Image src={f3} className={styles.founderImage} />
              </Col>
              <Col className={styles.founderImageContainer}>
                <Image src={f4} className={styles.founderImage} />
              </Col>
            </Row>
          </Col>
          <Col className="d-flex flex-column align-items-start justify-content-center col-12 col-md-6">
            <p className={styles.aboutHeader}>POPAGANDA</p>
            <p className={styles.textContent16} style={{marginBottom: "2.5rem"}}>
              Ron English coined the term POPaganda to describe his
              signature mash-up of high and low cultural touchstones,
              from superhero mythology to totems of art history,
              populated with his vast and constantly growing arsenal of
              original characters, including MC Supersized, the obese
              fast-food mascot featured in the hit movie "Supersize Me,"
              and Abraham Obama, the fusion of America’s 16th and 44th
              Presidents, an image widely discussed in the media as
              directly impacting the 2008 election.
            </p>
            <p className={styles.aboutHeader}>1XRUN</p>
            <p className={styles.textContent16}>
              Based in Detroit, Michigan, 1XRUN ("one-time run") is the world’s
              leading publisher of new contemporary art, and is a trusted source
              for authentic original art and print editions. Shipping to collectors
              all over the world, our staff of 20 has authenticated and published
              more than 4,000 limited-edition drops since 2011. Follow @1XRUN and
              visit 1XRUN.com to join our global community of collectors, artists, 
              and fans.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Home


async function setNonceForAddress(
  address: string,
  setModalTitle: (str: string) => void,
  setModalMessage: (str: string) => void,
  setNonce: (str: string) => void,
  setIsProcessingRequest: (bool: boolean) => void): Promise<void>
{
  const response = await axios.get(`https://api.lightcultcryptoclub.com/nonce?address=${address}`);

  if (!response.data || !response.data.nonce) {
    setModalTitle("Error")
    setModalMessage("Error authenticating wallet address. Please try again.");
    setIsProcessingRequest(false);
  }

  setNonce(response.data.nonce);
}

async function signAgreement(
  address: string,
  emailAddress: string | undefined,
  nonce: string,
  setSignature: (str: string) => void): Promise<void>
{
  const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
  let response;
  if (emailAddress !== undefined) {
    response = await web3.eth.personal.sign(
      `Click "Sign" to verify that you would like to make the update described in this message. We use this signature to ensure that you are a current LCCC owner and are eligible to continue in the Burn-For-Print process.\n\nPlease verify that the following information is correct:\n\nWallet address: ${address}\n\nEmail: ${emailAddress}\n\nUnique ID: ${nonce}`,
      address,
      ''
    );
  } else {
    response = await web3.eth.personal.sign(
      `Click "Sign" to verify that you would like to retrieve information from the address mentioned below. We use this signature to ensure that you are a current LCCC owner and are eligible to continue in the Burn-For-Print process.\n\nWallet address: ${address}\n\nUnique ID: ${nonce}`,
      address,
      ''
    );
  }

  if (response) {
    setSignature(response);
  }
}

async function recordEmail(
  address: string,
  email: string,
  signature: string,
  setModalTitle: (str: string) => void,
  setModalMessage: (str: string) => void,
  setEmail: (str: string) => void,
  setNonce: (str: string) => void,
  setSignature: (str: string) => void,
  setIsEditingEmail: (bool: boolean) => void,
  setIsProcessingRequest: (bool: boolean) => void): Promise<void>
{
  const response = await axios.put(
    "https://api.lightcultcryptoclub.com/physical",
    {
      address,
      signature,
      data: {
        email
      }
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status === 200) {
    setModalTitle("Success!")
    setModalMessage(`The email ${email} has successfully been associated with ${address}.`);
    setIsProcessingRequest(false);
    setEmail(email);
    setNonce("");
    setSignature("");
    setIsEditingEmail(false);
  } else {
    setModalTitle("Error");
    setModalMessage("Error associating email with the connected wallet address. Please try again.");
  }
  setIsProcessingRequest(false);
}

async function retrieveEmail(
  address: string,
  signature: string,
  setModalTitle: (str: string) => void,
  setModalMessage: (str: string) => void,
  setEmail: (str: string) => void,
  setUpdatedEmail: (str: string) => void,
  setIsRetrievingEmail: (bool: boolean) => void): Promise<void>
{
  const response = await axios.get(
    `https://api.lightcultcryptoclub.com/physical?address=${address}&signature=${signature}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status === 200)  {
    const email = response.data.email;
    setEmail(email);
    setUpdatedEmail(email);
  } else {
    setModalTitle("Error");
    setModalMessage(`Error retrieving the email address for the connected wallet. Please try again.`);
  }
  setIsRetrievingEmail(false);
}
